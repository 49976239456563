<template>
  <!-- <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
  </div> -->

  <main id="app"  :class="currentRouteName">

    <nprogress-container></nprogress-container>  

    <div class="header" :class="currentRouteName">
    <!-- <div class="header" :class="[{ scrolled: hasScrolled }, currentRouteName]"> -->
      <router-link to="/">
        <!-- <img v-if="currentRouteName == 'ListingsHome' && hasScrolled" class="logo" src="./assets/img/logo-header.png" alt="Xmarine">
        <img v-if="currentRouteName != 'ListingsHome'" class="logo" src="./assets/img/logo-header.png" alt="Xmarine"> -->
        <img class="logo" src="./assets/img/logo-header.png" alt="Xmarine">
      </router-link>
      <!-- <mobile-nav :class="[{ scrolled: hasScrolled }, currentRouteName]"></mobile-nav> -->
      <mobile-nav></mobile-nav>
    </div>

    <div class="header-spacer" :class="currentRouteName"></div>

    <router-view></router-view>

    <!-- <div class="notification force-refresh" @click="refreshApp" v-show="showRefreshButton">
      New content available!<br>Click to refresh.
    </div> -->

    <!-- <back-to-top bottom="60px" right="20px" visibleoffset="200">
      <div class="btt">
        <font-awesome-icon icon="chevron-up" />
      </div>
    </back-to-top> -->



  </main>


</template>





<script>

import firebase from 'firebase/app';
import 'firebase/functions';
import Header from './components/structure/Header.vue';
import MobileNav from './components/structure/MobileNav.vue';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';

import Vue from 'vue';

Vue.config.devtools = false;



// Custom Directive to close modals on click outside
let handleOutsideClick;
Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array from the object used in v-closable
      const { handler, exclude } = binding.value
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName]
          // See if this excluded element is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })
      // We check to see if the clicked element is not the modal element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the modal and not the button, then call the outside-click handler from the same component this directive is used in
        vnode.context[handler]()
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    // If the element that has v-closable is removed, then unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
});







export default {
  components: {
    mobileNav : MobileNav,
    NprogressContainer,
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  data() {
    return {
      showRefreshButton: false,
      registration: '',
      scrollPosition: null,
      // scrollPosition: 0,
      // steps: [
      //   {
      //     target: '[data-v-step="dashtour-step-1"]',
      //     content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
      //     params: {
      //       placement: 'top'
      //     }
      //   },
      //   {
      //     target: '[data-v-step="dashtour-step-2"]',
      //     content: 'This is the second step..',
      //     params: {
      //       placement: 'top'
      //     }
      //   }
      // ],

    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    currentRouteName() {
      return this.$route.name;
    },
    hasScrolled() {
      return this.scrollPosition > 60;
    }
  },
  methods: {
    // toggle: function (event) {
    //   this.isActive = !this.isActive;
    //   this.seen = !this.seen;
    // },
    setUser: function() {
      this.$store.dispatch('setUser');
    },

    updateScroll() {
      this.scrollPosition = window.scrollY;
      // console.log(this.scrollPosition);
    }

    // handleScroll(e) {
    //   var currentScrollPosition = e.srcElement.scrollTop;
    //   if (currentScrollPosition > this.scrollPosition) {
    //       console.log("Scrolling down");
    //   }
    //   this.scrollPosition = currentScrollPosition;
    // },

    // setIsAdmin: function() {
    //   this.$store.dispatch('setIsAdmin');
    // },

    // showRefreshUI(e) {
    //   // Display a snackbar inviting the user to refresh/reload the app due
    //   // to an app update being available.
    //   // The new service worker is installed, but not yet active.
    //   // Store the ServiceWorkerRegistration instance for later use.
    //   this.registration = e.detail;
    //   console.log(this.registration);
    //   this.showRefreshButton = true;
    // },

    // refreshApp() {
    //   this.showRefreshButton = false;

    //   const userName = this.user == null ? 'A visitor' : this.user.displayName;
    //   const userID = this.user == null ? 'no-user-id' : this.user.uid;
    //   // var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
    //   // userHasEngaged({
    //   //   userID: userID, 
    //   //   userName: userName,
    //   //   message: 'A user has downloaded new content in the app',
    //   //   subject: 'A user has refreshed the app'
    //   // }); 
    //   //
      
    //   // var generalEmail = firebase.functions().httpsCallable('generalEmail');
    //   // generalEmail({
    //   //   message: 'A user has downloaded new content in the app',
    //   //   subject: 'A user has refreshed the app'
    //   // });

    //   // Protect against missing registration.waiting.
    //   if (!this.registration || !this.registration.waiting) { return; }

    //   this.registration.waiting.postMessage('skipWaiting');


    // },

    // hideNotification: function() {
    //   //console.log("boo")
    //   this.showRefreshButton = true;
    // }


  },

  
  created() {
    // when the app is created run the set user method
    // this uses Vuex to check if a user is signed in
    // check out mutations in the store.js file
    // window.addEventListener('scroll', this.handleScroll);
    this.$store.dispatch('SETUSER');
    this.$store.dispatch('SETUSERDATA');
    // this.setIsAdmin();
    // this.$store.dispatch('SETSUBSCRIPTIONINFO');

    // Listen for swUpdated event and display refresh snackbar as required.
    // document.addEventListener('swUpdated', this.showRefreshUI, { once: true });

    // // Refresh all open app tabs when a new service worker is installed.
    // navigator.serviceWorker.addEventListener('controllerchange', () => {
    //   if (this.refreshing) return;
    //   this.refreshing = true;
    //   window.location.reload();
    // });


  },
  destroyed () {
    // window.removeEventListener('scroll', this.handleScroll);
  },



};
</script>








<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .header-spacer {
    &.ListingsHome {
      height: 0;
    }
  }
}

// .header {
//   &.ListingsHome {
//     background: transparent;
//     border-bottom: none;
//     &.scrolled {
//       background: #fff;
//       border-bottom: 1px solid #e4e4e4;
//     }
//   }
// }


#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#mobilenavwrapper {
  &.ListingsHome {
    #nav-icon {
      span {
        background: #fff;
      }
    }
    &.scrolled {
      #nav-icon {
        span {
          background: #245e8b;
        }
      }
    }
  }
}

  


</style>
