import Vue from 'vue';
Vue.config.devtools = true;
import Vuex from 'vuex';
import firebase from 'firebase/app';
import 'firebase/auth'; 
import 'firebase/firestore';
//import * as moment from 'moment';

import { db } from '../main.js';

Vue.use(Vuex);
//Vue.use(require('vue-moment'));

export const store = new Vuex.Store({
  state: {
    user: null,
    userData: {
      username: '',

    },
    
  },
  getters: {
    
    getUser: state => {
      return state.user;
    },
    getUserData: state => {
      return state.userData;
    },
    

    


  },
  mutations: {

    RESETSTATES: state => {
      state.user = null;
    },

    SETUSERDATA: state => {
      if (state.user) {
        db.collection('userdata').doc(state.user.uid).onSnapshot(function(doc) {
          if (doc.exists) {
            state.userData.userid = doc.id;
            state.userData.username = doc.data().username ? doc.data().username : 'No name';
            state.userData.useremail = doc.data().useremail ? doc.data().useremail : '';
            state.userData.userphone = doc.data().userphone ? doc.data().userphone : '';
          }
        });
      }
    },

    SETUSER: state => {
      state.user = firebase.auth().currentUser;
    },
    UPDATEUSERSTATE: state => {
      var user = firebase.auth().currentUser;
      if (user) {
        console.log('Have user');
        // alternative test for user admin => check if starter or fleet user role
        db.collection('userdata').doc(user.uid).onSnapshot(function(doc) {
          if (doc.exists) {
            // state.isUserAdmin = (doc.data().userrole == 'free' ? false : true);
            // state.subscriptionInfo.userrole = doc.data().userrole ? doc.data().userrole : '';
          }
        });
      }
    },
    


  },


  actions: {

    SETUSER: context => {
      context.commit('SETUSER');
    },
    SETUSERDATA: context => {
      context.commit('SETUSERDATA');
    },
    resetStates: context => {
      context.commit('RESETSTATES');
    },
    updateUserState: context => {
      context.commit('UPDATEUSERSTATE');
    },
    


  }
});
