import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
// import router from './router'
// import store from './store'

import VueRouter from 'vue-router';
import { store } from './store/store';
import { routes } from './router/routes';

Vue.config.productionTip = false



import firebase from 'firebase/app';
// import firebaseui from 'firebaseui';

import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
//import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
//import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions



import BackToTop from 'vue-backtotop';
Vue.use(BackToTop);


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import vueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(vueSmoothScroll)

import VueSignaturePad from 'vue-signature-pad'; 
Vue.use(VueSignaturePad);

import { 
  faCoffee, faUserAlt, faShip, faExclamationTriangle, faUser, faUsers, faFish, faFire, faHome, faBriefcase, faUserGraduate, faCloud, faLifeRing, faCheckCircle, faMoneyBillAlt, faThumbsUp, faLink, faInfo, faInfoCircle, faQuestionCircle, faCog, faList, faTimes, faCheck, faEnvelope, faPhone, faChevronUp, faChevronDown, faChevronLeft, faChevronRight, faLock, faShoppingCart, faWrench, faStopwatch, faOilCan, faStar, faVolumeUp, faPrint, faRegistered,
  faFileSignature, faListUl, faClipboardList, faAnchor, faPlay, faPen, faSearch, faCamera, faCalendarCheck, faTrashAlt, faSyncAlt, faGasPump, faLocationArrow, faComments, faLightbulb, faHeart, 
} from '@fortawesome/free-solid-svg-icons'

import {
  // faLinkedin,
  // faTwitter,
  faFacebookF,
  faFacebookSquare,
  faLinkedin,
  //faInstagram,
} from '@fortawesome/free-brands-svg-icons';

library.add(faCoffee, faUserAlt, faShip, faUsers, faUser, faFish, faExclamationTriangle, 
  faFire, faHome, faBriefcase, faUserGraduate, faCloud, faLifeRing, faCheckCircle, faMoneyBillAlt, 
  faThumbsUp, faLink, faInfoCircle, faInfo, faQuestionCircle, faCog, faList, faTimes, faCheck, faEnvelope, faPhone,
  faChevronUp, faChevronDown, faLock, faFacebookF, faShoppingCart, faWrench, faStopwatch, faOilCan, faStar, faChevronLeft,
  faChevronRight, faVolumeUp, faPrint, faFileSignature, faListUl, faClipboardList, faAnchor, faPlay, faPen, faRegistered, faSearch, faCamera, faTrashAlt, 
  faCalendarCheck, faSyncAlt, faGasPump, faLinkedin, faFacebookSquare, faLocationArrow, faComments, faLightbulb, faHeart,     )


Vue.component('font-awesome-icon', FontAwesomeIcon);



import './assets/css/style.scss';

import NProgress from 'nprogress';
import VueProgress from 'vue-progress'
Vue.use(VueProgress)


// import Parse from 'parse/dist/parse.min.js';
// import Parse from 'parse';


// Parse.serverURL = 'https://parseapi.back4app.com/'; // This is your Server URL
// // Remember to inform BOTH the Back4App Application ID AND the JavaScript KEY
// // Parse.initialize("YOUR-APP-ID", "YOUR-JS-KEY");

// Parse.initialize(
//   'kUh47tsew2Nr4jhmr5Sq7aOKDPhf11teTgiofSHW', // This is your Application ID
//   'UyP8B3itrwiHxvpRBI9mb9GqKA31VwCpnfHYhCOl', // This is your Javascript key
//   'Pa3K8IV8X8HqopEQ3DYzwwZdmft9jd9rvwxOyUeO' // This is your Master key (never use it in the frontend)
// );


import dropzone from 'vue2-dropzone';
import "vue2-dropzone/dist/vue2Dropzone.min.css";



import VuejsDialog from 'vuejs-dialog';
// import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog);



// import CustomGoogleAutocomplete from 'vue-custom-google-autocomplete'
// Vue.use(CustomGoogleAutocomplete)


Vue.use(VueRouter);

const router = new VueRouter({
  routes: routes,
  mode: 'history'
});




// Firebase config - this is provided when you create your app
// Swap out these settings for your project settings
const config = {
  apiKey: "AIzaSyDL_bQSHiqBeczDCRR3_HcQjXfbJDHt3RA",
  authDomain: "app-testing-e2b25.firebaseapp.com",
  databaseURL: "https://app-testing-e2b25.firebaseio.com",
  projectId: "app-testing-e2b25",
  storageBucket: "app-testing-e2b25.appspot.com",
  messagingSenderId: "975927122519",
  appId: "1:975927122519:web:7d0b8360cd78371ae6e112"
};


//Vue.use(VueFire);


  // const firestore = new Firestore();
  // const settings = {timestampsInSnapshots: true};
  // firestore.settings(settings);

// Initialise Cloud Functions
//var functions = firebase.functions();

// Initialize Firebase
firebase.initializeApp(config);

export const db = firebase.firestore();
// date issue fix according to firebase
export const settings = { 
    //timestampsInSnapshots: true, //deprecated
    // persistence: false,
};
db.settings(settings);






router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      // NProgress.start()
  }
  next();
});


router.beforeEach((to, from, next) => {

  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next('/sign-in');
  } else if (requiresAuth && currentUser) {
    window.scrollTo(0, 0)
    next();
  } else {    
    window.scrollTo(0, 0)
    next();
  }

});


router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
});


// Wrap the vue instance in a Firebase onAuthStateChanged method
// This stops the execution of the navigation guard 'beforeEach'
// method until the Firebase initialization ends
firebase.auth().onAuthStateChanged(function (user) {

  new Vue({
    el: '#app',
    store: store,
    router: router,
    render: h => h(App),
  });

});





// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
