

export const routes = [ 

  // () => import(/* webpackChunkName: "group-dashboard" */ '../components/')
  
  // { path: '', name: 'home', component: () => import( '../components/Home.vue') },
  
  { path: '/home-mvp', name: 'HomePage', component: () => import(/* webpackChunkName: "home" */ '../components/dashboard/HomePage.vue') },
  // { path: '/', name: 'ListingsHome', component: () => import(/* webpackChunkName: "home" */ '../components/dashboard/ListingsHome.vue') },
  { path: '/', name: 'ListingsHome', component: () => import(/* webpackChunkName: "home" */ '../components/dashboard/HomePage.vue') },

  { path: '/sign-in', name: 'signIn', component: () => import(/* webpackChunkName: "group-welcome" */ '../components/signing/SignIn'), props: true },
  { path: '/sign-up', name: 'signUp', component: () => import(/* webpackChunkName: "group-welcome" */ '../components/signing/SignUp'), props: true },

  { path: '/our-story', name: 'OurStory', component: () => import(/* webpackChunkName: "general" */ '../components/general/OurStory'), props: true },

  { path: '/my-dashboard', name: 'MyDashboard', component: () => import(/* webpackChunkName: "group-dashboard" */ '../components/dashboard/MyDashboard.vue'), meta: { requiresAuth: true }, props: true  },

  // { path: '/home-page', name: 'HomePage', component: () => import(/* webpackChunkName: "group-dashboard" */ '../components/dashboard/HomePage.vue'), props: true  },
 

  { path: '/new-listing-start', name: 'NewListingStart', component: () => import(/* webpackChunkName: "new-listing" */ '../components/new-listing/NewListingStart'), props: true },
  { path: '/new-listing-details', name: 'NewListingDetails', component: () => import(/* webpackChunkName: "new-listing" */ '../components/new-listing/NewListingDetails'), meta: { requiresAuth: true }, props: true },


  { path: '/404', name: '404', component: () => import('../components/structure/Error404')  },

  { path: '*', redirect: '/'  }
]